import { filter } from '@prismicio/client';
import { GetStaticPropsResult, NextPage } from 'next';
import { NextSeo } from 'next-seo';
import Script from 'next/script';
import React from 'react';
import {
  About,
  Blog,
  Contact,
  Header,
  Layout,
  Testimonial,
} from '../components';

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { i18nConfig } from '../next-i18next.config';
import { Client } from '../prismic-configuration';
import { IndexProps, Locale } from '../types';
import getPrismicMenuByLocale from '../utils/prismic/menu';
import getPrismicOptionsByLocale from '../utils/prismic/options';
import { getPrismicSingleItem } from '../utils/prismic/single-item';
import { REVALIDATION_TIME } from '../utils/constants';

export const Index: NextPage<IndexProps> = (props) => {
  return (
    <React.Fragment>
      <NextSeo title="Prickle" description="Prickle Website" />
      <Script src="https://cdn.rawgit.com/progers/pathseg/master/pathseg.js"></Script>
      <Layout options={props.options} menu={props.menu}>
        <Header
          data={{
            title: props.home.data.header_message,
            description: props.home.data.header_description,
          }}
        />
        <About
          data={{
            title: props.home.data.about_title,
            description: props.home.data.about_description,
          }}
          logo={props.options.basic_logo.url}
        />
        {/* <Team
          data={{
            title: props.home.data.team_title,
            description: props.home.data.team_description,
          }}
          members={props.team_members}
        /> */}
        {process.env.NEXT_PUBLIC_TESTIMONIALS === 'true' &&
        props.testimonials ? (
          <Testimonial data={props.testimonials} />
        ) : (
          <> </>
        )}
        {process.env.NEXT_PUBLIC_ENABLE_BLOG === 'true' &&
        props.posts.length > 0 ? (
          <Blog articles={props.posts} />
        ) : (
          <> </>
        )}
        <Contact />
      </Layout>
    </React.Fragment>
  );
};

export const getStaticProps = async ({
  locale,
  previewData,
}: {
  locale: Locale;
  previewData: any;
}): Promise<GetStaticPropsResult<IndexProps>> => {
  const menu = await getPrismicMenuByLocale(locale, previewData);
  const options = await getPrismicOptionsByLocale(locale, previewData);
  const home = await getPrismicSingleItem('homepage', locale);
  let testimonials;
  let posts;
  try {
    const client = Client({ previewData: previewData });
    testimonials = await client.get({
      predicates: [filter.at('document.type', 'testimonials')],
      lang: locale,
    });

    posts = await client.get({
      predicates: [filter.at('document.type', 'article')],
      fetchLinks: ['authors.name', 'authors.image'],
      orderings: {
        field: 'my.post.date',
        direction: 'desc',
      },
      lang: locale,
    });
  } catch (error: any) {
    if (
      error.message === 'No documents were returned' ||
      error === 'No documents were returned'
    ) {
      return {
        notFound: true,
      };
    }
    throw new Error(error);
  }

  return {
    revalidate: REVALIDATION_TIME,
    props: {
      ...(await serverSideTranslations(
        locale,
        ['services', 'homepage', 'common'],
        i18nConfig,
      )),
      home: home,
      options,
      menu,
      posts: posts.results,
      testimonials: testimonials.results,
      // team_members: team_members.results.reverse(),
    },
  };
};
export default Index;
